
import i18n from "@/setup/i18n";
import store from "@/store";
import {
  Company,
  CompanyProjectRole,
  Contributor,
  ContributorBatch,
  Role,
  utils,
} from "@socotec.io/socio-vue-components";
import dayjs from "dayjs";
import { useService } from "@/setup/connectClient";
import { ContributorController, CompanyProjectRoleController, BatchController, ManagerLogController } from "@socotec.io/socio-grpc-api/connect/services/contributors/contributors_pb";
import { ProjectController } from "@socotec.io/socio-grpc-api/connect/services/rapsosps_back/projects_pb";
import { listAll } from "@socotec.io/socio-grpc-api/connect/utils";

const contributorClient = useService(ContributorController);
const companyProjectRoleClient = useService(CompanyProjectRoleController);
const batchClient = useService(BatchController);
const managerLogClient = useService(ManagerLogController);
const projectClient = useService(ProjectController);

const messages = {
  en: {
    successSwitchProjectManager: "The coordinators were manually switched",
    failedSwitchProjectManager:
      "Un unknown error occured when swapped manually, please contact support",
  },
  fr: {
    successSwitchProjectManager:
      "Les coordonnateurs ont été intervertis manuellement",
    failedSwitchProjectManager:
      "Une erreur s'est produite lors du changement manuel de pilote, veuillez contacter le support",
  },
};

i18n.mergeLocaleMessage("fr", messages.fr);
i18n.mergeLocaleMessage("en", messages.en);

const { CONTRIBUTORS_ENUMS } = utils.contributors;
const UPDATE_CONTRIBUTORS_COUNT = "UPDATE_CONTRIBUTORS_COUNT";
const ADD_CONTRIBUTORS_COUNT = "ADD_CONTRIBUTORS_COUNT";
const UPDATE_MANAGER_HISTORIC = "UPDATE_MANAGER_HISTORIC";
const UPDATE_MANAGERS = "UPDATE_MANAGERS";
const RESET_CONTRIBUTORS_STATE = "RESET_CONTRIBUTORS_STATE";
const UPDATE_COMPANY_PROJECT_COUNT = "UPDATE_COMPANY_PROJECT_COUNT";
const ADD_COMPANY_PROJECT_COUNT = "ADD_COMPANY_PROJECT_COUNT";

const state = {
  contributorsCount: 0,
  companyProjectRoleCount: 0,
  managerHistoric: {},
  manager: {},
  substituteManager: {},
};

// TODO: Remove the mapping when SVC up to date
const contributorResponseMapping = (response) => {
  const { batches, ...companyProjectRole } = response.companyProjectRole || {};
  return {
    ...response,
    companyProjectRole: {
      ...companyProjectRole,
      batchCompanyProjectRoleList: batches,
      batchesList: batches?.map((batch) => batch.batchId),
    },
  };
};

const companyProjectRoleResponseMapping = (response) => {
  const { batches, ...companyProjectRole } = response;
  return {
  ...companyProjectRole,
  batchCompanyProjectRoleList: batches,
  batchesList: batches.map((batch) => batch.batchId),
  contributors: response.contributors.map(contributorResponseMapping),
};
}

const getters = {
  getContributorsCount: (state) => {
    return state.contributorsCount;
  },
  getCompanyProjectRoleCount: (state) => {
    return state.companyProjectRoleCount;
  },
  projectContributors: () => (operationId) => {
    return Contributor.query()
      .whereHas("companyProjectRole", (query) => {
        query.where("projectId", operationId);
      })
      .with("companyProjectRole.role")
      .with("companyProjectRole.company")
      .with("companyProjectRole.batches")
      .get()
      .map((contributor) => {
        return {
          ...contributor,
          role: contributor.companyProjectRole?.role,
          company: contributor.companyProjectRole?.company,
          batches: contributor.companyProjectRole?.batches,
          roleAlias: contributor.companyProjectRole?.roleAlias,
          workDone: contributor.companyProjectRole?.workDone,
          projectedStaffing: contributor.companyProjectRole?.projectedStaffing,
        };
      });
  },
  getCompanyByUuid: () => (uuid) => {
    return Company.find(uuid);
  },
  managerHistoric: (state) => {
    return state.managerHistoric;
  },
  manager: (state) => {
    return state.manager;
  },
  substituteManager: (state) => {
    return state.substituteManager;
  },
};

const actions = {
  /**
   * Fetch contributors
   * @param commit
   * @param metadata
   * @returns {Promise<Contributor[]>}
   */
  async fetchContributors({ commit }, metadata) {
    const response = await contributorClient.list({}, { headers: metadata });
    const contributorsList = response.results.map(contributorResponseMapping);

    commit(UPDATE_CONTRIBUTORS_COUNT, response.count);
    return await Contributor.create({
      data: contributorsList,
      insert: ["role", "company", "companyProjectRole"],
    });
  },

  async listContributors(_, metadata) {
    const response = await contributorClient.list({}, { headers: metadata });
    return response.results;
  },

  async fetchAllContributors({ commit }, params) {
    const response = await listAll(contributorClient.list, {}, params.metadata);
    if (params.updateCount || params.updateCount == null) {
      commit(UPDATE_CONTRIBUTORS_COUNT, response.length);
    }
    const result = response.map(contributorResponseMapping);
    return await Contributor.create({
      data: result,
      insert: ["role", "company", "companyProjectRole"],
    });
  },

  async fetchAllCompanyWithContributors({ commit }, params) {
    const response = await companyProjectRoleClient.list({}, { headers: params.metadata });
    const companyProjectRoleList = response.results.map((companyProjectRole) => ({
      ...companyProjectRoleResponseMapping(companyProjectRole),
      companyId: companyProjectRole.company?.uuid,
      projectId: companyProjectRole.projectId,
      roleId: companyProjectRole.role?.uuid,
    }));
    commit(UPDATE_COMPANY_PROJECT_COUNT, response.count);
    const a = await CompanyProjectRole.create({
      data: companyProjectRoleList,
      insert: ["role", "company", "project", "contributors"],
    });
    return a;
  },

  async fetchContributorsByDistinctBash({ commit }, metadata) {
    const response = await contributorClient.listContributorByDistinctBatch({}, { headers: metadata });
    commit(UPDATE_CONTRIBUTORS_COUNT, response.count);
    return response.results;
  },

  async createContributor({ commit, rootGetters }, contributor) {
    const response = await contributorClient.create({
      ...contributor,
      invitationByUsermanagementUuid: rootGetters["user/getCurrentUser"].usermanagementUuid,
      synchronized: true,
    });
    commit(ADD_CONTRIBUTORS_COUNT, 1);
    const dataInserted = await Contributor.insert({ data: contributorResponseMapping(response) });
    return dataInserted.contributor[0].uuid;
  },

  async createCompanyProjectRole({ commit }, companyProjectRole) {
    // Company needs to be empty when there is a companyId
    const response = await companyProjectRoleClient.getOrCreate({...companyProjectRole, company: undefined});
    commit(ADD_COMPANY_PROJECT_COUNT, 1);
    const dataInserted = await CompanyProjectRole.create({
      data: companyProjectRoleResponseMapping(response),
      insert: ["role", "company"],
    });
    return dataInserted.companyProjectRole[0]?.uuid;
  },
  
  async updateCompanyProjectRole(_, companyProjectRole) {
    // Company needs to be empty when there is a companyId
    const response = await companyProjectRoleClient.update({...companyProjectRole, company: undefined});
    let companyProjectRoleResponse = companyProjectRoleResponseMapping(response)
    const dataInserted = await CompanyProjectRole.insert({
      data: companyProjectRoleResponse,
      insert: ["role", "company", "project", "contributors"],
    });
    return dataInserted.uuid;
  },

  async updateContributor(_, contributor) {
    const response = await contributorClient.update(contributor);
    const dataUpdated = await Contributor.update({
      where: contributor.uuid,
      data: contributorResponseMapping(response),
    });
    return dataUpdated.uuid;
  },

  async deleteContributor({ commit }, contributor) {
    await contributorClient.destroy({ uuid: contributor.uuid }, {
      headers: {
        filters: JSON.stringify({
          check_company_project: false,
        })
      }
    });
    commit(ADD_CONTRIBUTORS_COUNT, -1);
    await Contributor.delete(contributor.uuid);
  },

  async deleteCompanyProjectRole({ commit }, companyProjectRole) {
    await companyProjectRoleClient.destroy({ uuid: companyProjectRole.uuid });
    commit(ADD_COMPANY_PROJECT_COUNT, -1);
    await CompanyProjectRole.delete(companyProjectRole.uuid);
  },

  async assignBatches(context, { contributorId, batches }) {
    const batchesToCreate = batches.map((b) => ({
      batchId: b.uuid,
      contributorId: contributorId,
    }));
    await batchClient.bulkCreate({ batches: batchesToCreate });
    batches.forEach((b) => {
      ContributorBatch.insert({
        data: {
          batchId: b.uuid,
          contributorId: contributorId,
          companyProjectRoleId: contributorId,
        },
      });
    });
  },

  async assignBatchesNew(context, { companyProjectRoleId, batches }) {
    const batchesToCreate = batches.map((b) => ({
      batchId: b.uuid,
      companyProjectRoleId: companyProjectRoleId,
      incumbentCompanyName: b.incumbentCompanyName,
      companyRole: b.companyRole,
    }));
    await batchClient.bulkCreate({ batches: batchesToCreate });
    batches.forEach((b) => {
      ContributorBatch.insert({
        data: {
          batchId: b.uuid,
          companyProjectRoleId: companyProjectRoleId,
          incumbentCompanyName: b.incumbentCompanyName,
          companyRole: b.companyRole,
        },
      });
    });
  },

  async updateBatches(context, { batches }) {
    await batchClient.bulkUpdate({ batches });
    batches.forEach((b) => {
      ContributorBatch.insert({
        data: {
          batchId: b.uuid,
          companyProjectRoleId: b.companyProjectRoleId,
          incumbentCompanyName: b.incumbentCompanyName,
          companyRole: b.companyRole,
        },
      });
    });
  },

  async updateBatch(context, { batch }) {
    const response = await batchClient.update(batch);
    await ContributorBatch.insert({
      data: response,
    });
  },

  async unassignBatches(context, { contributorId, batches }) {
    const compositePks = batches.map((b) => [b.uuid, contributorId]);
    const contributorBatches = await ContributorBatch.query().findIn(
      compositePks
    );
    const contributorBatchIds = contributorBatches.map((b) => b.uuid);
    await batchClient.bulkDestroy({ uuids: contributorBatchIds });
    for (const batch of contributorBatches) {
      batch.$delete();
    }
  },

  async unassignBatchesNew(context, { batches }) {
    const contributorBatchIds = batches.map((b) => b.uuid);
    await batchClient.bulkDestroy({ uuids: contributorBatchIds });
  },

  async toggleArchiveContributor(context, uuid) {
    await contributorClient.archive({ uuid });
    const contributor = Contributor.find(uuid);
    contributor.isArchived = !contributor.isArchived;
    contributor.$save();
  },

  async toggleArchiveCompany(context, uuid) {
    await companyProjectRoleClient.archive({ uuid });
    const companyProjectRole = CompanyProjectRole.query()
      .with("contributors")
      .find(uuid);
    companyProjectRole.isArchived = !companyProjectRole.isArchived;
    companyProjectRole.contributors.forEach((contributor) => {
      contributor.isArchived = !companyProjectRole.isArchived;
      contributor.$save();
    });
    companyProjectRole.$save();
  },

  async fetchProjectHistoric({ commit }, projectId) {
    const response = await managerLogClient.list({}, {
      headers: {
        filters: JSON.stringify({
          projectIds: [projectId],
        }),
      },
    });
    const historic = {};
    response.results.forEach((log) => {
      const logDate = dayjs(log.createdAt).format("DD/MM/YYYY");
      if (!historic[logDate]) {
        historic[logDate] = [];
      }
      historic[logDate].push(log);
    });
    commit(UPDATE_MANAGER_HISTORIC, historic);
  },

  async setProjectManagers({ commit }, projectId) {
    //HACK - B.L - 22/10/2021 - Complicated query for no other reason than vuexorm not filtering correctly:
    // const manager = Contributor.query()
    //   .where("isArchived", false)
    //   .with("role", (query) => {
    //     return query.where("name", CONTRIBUTORS_ENUMS.MANAGER_ROLE);
    //   })
    //   .first();
    // like https://vuex-orm.org/guide/data/retrieving.html#relationship-constraints does not work.

    const managerRoleUuid =
      Role.query().where("name", CONTRIBUTORS_ENUMS.MANAGER_ROLE).first()
        ?.uuid || null;
    const substituteManagerRoleUuid =
      Role.query().where("name", "SUB_COORDONNATEUR_SPS").first()?.uuid || null;
    const manager = Contributor.query()
      .where((contributor) => {
        return contributor.isArchived === false && managerRoleUuid !== null;
      })
      .whereHas("companyProjectRole", (query) =>
        query.where("projectId", projectId).where("roleId", managerRoleUuid)
      )
      .first() || { userFirstName: "", userLastName: "" };

    const substituteManager = Contributor.query()
      .where((contributor) => {
        return (
          contributor.isArchived === false && substituteManagerRoleUuid !== null
        );
      })
      .whereHas("companyProjectRole", (query) =>
        query
          .where("projectId", projectId)
          .where("roleId", substituteManagerRoleUuid)
      )
      .first() || { userFirstName: "", userLastName: "" };
    commit(UPDATE_MANAGERS, { manager, substituteManager });
  },

  async switchProjectManager({ commit, state, dispatch }, projectId) {
    try {
      await projectClient.swapManagers({ uuid: projectId });
      await commit(UPDATE_MANAGERS, {
        manager: state.substituteManager,
        substituteManager: state.manager,
      });
      await dispatch("fetchProjectHistoric", projectId);
      await store.dispatch(
        "notifications/showSuccessNotification",
        i18n.t("successSwitchProjectManager")
      );
    } catch (err) {
      await store.dispatch(
        "notifications/showErrorNotification",
        i18n.t("failedSwitchProjectManager")
      );
    }
  },

  async updateProjectManagers({ rootGetters }, coordinatorsObject) {
    const projectUuid = rootGetters["operation/getOperationUuid"];
    try {
      await projectClient.updateManagers({
        uuid: projectUuid,
        managerEmail: coordinatorsObject.coordinator.email,
        managerSocotecCode: coordinatorsObject.coordinator.socotecCode,
        managerUsermanagementUuid: coordinatorsObject.coordinator.uuid,
        substituteManagerEmail: coordinatorsObject.subCoordinator.email,
        substituteManagerSocotecCode: coordinatorsObject.subCoordinator.socotecCode,
        substituteManagerUsermanagementUuid: coordinatorsObject.subCoordinator.uuid,
      });
    } catch (error) {
      console.error("Cannot update Coordinators :\n", error);
      return;
    }
  },

  async RetrieveContributorByUserAndProjectUuid(_, { usermanagementUuid, projectId }) {
    const response = await contributorClient.retrieveByUsermanagementUuidForGivenProject({
      usermanagementUuid,
      projectId,
    });
    return response;
  }
};

const mutations = {
  [UPDATE_CONTRIBUTORS_COUNT]: (state, newTotal) => {
    state.contributorsCount = newTotal;
  },
  [ADD_CONTRIBUTORS_COUNT]: (state, number) => {
    state.contributorsCount += number;
  },
  [UPDATE_COMPANY_PROJECT_COUNT]: (state, newTotal) => {
    state.companyProjectRoleCount = newTotal;
  },
  [ADD_COMPANY_PROJECT_COUNT]: (state, number) => {
    state.companyProjectRoleCount += number;
  },
  [UPDATE_MANAGER_HISTORIC]: (state, historic) => {
    state.managerHistoric = historic;
  },
  [UPDATE_MANAGERS]: (state, { manager, substituteManager }) => {
    state.manager = manager;
    state.substituteManager = substituteManager;
  },
  [RESET_CONTRIBUTORS_STATE]: (state) => {
    state.contributorsCount = 0;
    state.managerHistoric = {};
    state.manager = {};
    state.substituteManager = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export { CONTRIBUTORS_ENUMS };